
$(window).on('load', function(){

	if ('undefined'===typeof CKEDITOR) return

	// styles
	CKEDITOR.stylesSet.add( 'my_styles',
	[
		{ name: '見出し（四角）', element: 'h4', attributes: { class: 'Midashi-square' } },
		{ name: '見出し（時計）', element: 'h3', attributes: { 'class': 'MidashiClock' } },
		{ name: '見出し（ピン）', element: 'h3', attributes: { 'class': 'MidashiMap' } },
		{ name: '見出し（電話）', element: 'h3', attributes: { 'class': 'MidashiTel' } },
		{ name: '見出し（レ点+点線）', element: 'p', attributes: { 'class': 'MidashiPrice' } },
		{ name: '左寄せ', element: 'p', attributes: { class: 'TL' } },
		{ name: '中央寄せ', element: 'p', attributes: { class: 'TC' } },
		{ name: '右寄せ', element: 'p', attributes: { class: 'TR' } },
		{ name: '赤文字', element: 'span', attributes: { 'class': 'red' } },
		{ name: '小さい文字', element: 'span', attributes: { 'class': 'small' } },
		{ name: 'ラベルアイコン（赤）', element: 'span', attributes: { 'class': 'Icon' } },
		{ name: '枠付きリンクにする', element: 'a', attributes: { 'class': 'News-link-frame' } },
		{ name: '下線なしにする', element: 'a', attributes: { 'class': 'none' } },
		{ name: 'ゴシック体にする', element: 'span', attributes: { 'class': 'fontG' } },
	]);

	// load ckeditor
	setTimeout(function(){
		$('[data-ckeditor]').each(function(){
			ckeditor_load($(this))
		})
	},0) // setTimeoutはさまないとIE11で正常にロードされないため
})



function ckeditor_load($target)
{
	// __BASE__取得
	var __BASE__ = $('base').attr('href').replace(/\/$/, '')

	// 新着情報用
	if ('news' === $target.data('ckeditor')) {

		var cke = CKEDITOR.replace($target.get(0),
			{
				allowedContent: true,

				// color chart
				colorButton_colors:
					'ffffff,cccccc,999999,666666,333333,000000,'+
					'003366,336699,003399,000099,000066,000066,'+
					'009999,33cccc,0099ff,0066ff,3366ff,3333cc,'+
					'00ff99,66ffcc,66ccff,99ccff,9999ff,9966ff,'+
					'66ff66,99ff99,ffffff,ffccff,ff99ff,ff66ff,'+
					'99ff33,ccff66,ffcc99,ff9999,ff6699,ff3399,'+
					'cccc00,ffff00,ff9933,ff6600,ff5050,cc0066,'+
					'663300,996600,993300,990000,800000,a50021,'+
					'003366,008080,006600,336600,808000,663300,'+
					'000066,696999,9900cc,800080,660033,a50021',

				// editor id
				contentsCss: __BASE__+'/assets/manage/css/add-ckeditor.css?20200227-01',

				// style set
				stylesSet: 'my_styles',

				// icons
				// toolbarGroups: [
				// 	// { name: 'styles', groups: ['styles'] },
				// 	// { name: 'clipboard',   groups: [ 'clipboard', 'undo' ] },
				// 	// { name: 'editing',     groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
				// 	// { name: 'forms',       groups: [ 'forms' ] },
				// 	{ name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
				// 	{ name: 'colors', groups: ['colors'] },
				// 	// { name: 'paragraph',   groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
				// 	{ name: 'links', groups: ['links'] },
				// 	{ name: 'insert', groups: ['insert'] },
				// 	// { name: 'tools',       groups: [ 'tools' ] },
				// 	// { name: 'others',      groups: [ 'others' ] },
				// 	// { name: 'about',       groups: [ 'about' ] },
				// 	{ name: 'document', groups: ['mode', 'document', 'doctools', 'Recommend'] },
				// 	{ name: 'custom', items: ['Recommend'] }
				// ],

				toolbar: [
					['Styles','Bold','Underline','Strike','Italic','RemoveFormat'],
					['TextColor','BGColor'],
					['Link','Unlink'],
					['Image', 'Table'],
					['Source'],
					['Recommend1', 'Recommend2', 'Recommend3'],
				],

				removeButtons: 'Templates,Save,NewPage,Preview,Print,Cut,Undo,Redo,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Subscript,Superscript,CopyFormatting,Outdent,Indent,Blockquote,CreateDiv,NumberedList,BidiLtr,BidiRtl,Language,Anchor,Flash,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Font,Format,FontSize,ShowBlocks,Maximize,About,BGColor',

				// for KCFinder
				filebrowserBrowseUrl: __BASE__+'/assets/libs/kcfinder/browse.php?type=files',
				filebrowserImageBrowseUrl: __BASE__+'/assets/libs/kcfinder/browse.php?type=images',
				filebrowserFlashBrowseUrl: __BASE__+'/assets/libs/kcfinder/browse.php?type=flash',
				filebrowserUploadUrl: __BASE__+'/assets/libs/kcfinder/upload.php?type=files',
				filebrowserImageUploadUrl: __BASE__+'/assets/libs/kcfinder/upload.php?type=images',
				filebrowserFlashUploadUrl: __BASE__+'/assets/libs/kcfinder/upload.php?type=flash',
				filebrowserUploadMethod: 'form',

				// plugins
				extraPlugins: 'autogrow,widget,lineutils,image2,justify,colorbutton',
				removePlugins: 'image',

				// plugin: Auto Grow
				autoGrow_minHeight: 200,
				autoGrow_maxHeight: 600,
				autoGrow_bottomSpace: 50,
			})
		;

		// 雛形挿入ボタン
		cke.on('pluginsLoaded', function(ev)
		{
			// 見出し（線）
			this.ui.addButton('Recommend1', {
				label:   '見出し（線）挿入',
				command: 'recommend1',
				icon:    __BASE__+'/assets/libs/fontawesome-free-5.9.0-web/svgs/solid/heading.svg'
			});
			this.addCommand('recommend1', {
				exec: function(editor) {
					editor.insertHtml(
						'<div class="centerring"><h3 class="MidashiWline">（ここに見出し）</h3></div>'
					);
				}
			});

			// テーブル
			this.ui.addButton('Recommend2', {
				label:   '新着用テーブル挿入',
				command: 'recommend2',
				icon:    __BASE__+'/assets/libs/fontawesome-free-5.9.0-web/svgs/solid/table.svg'
			});
			this.addCommand('recommend2', {
				exec: function(editor) {
					editor.insertHtml(
						'<table width="100%" border="0" class="news-table"><tbody><tr><th scope="row">項目1</th><td>（ここに内容）</td></tr><tr><th scope="row">項目2</th><td>（ここに内容）</td></tr><tr><th scope="row">項目3</th><td>（ここに内容）</td></tr></tbody></table>'
					);
				}
			});

			// リスト
			this.ui.addButton('Recommend3', {
				label:   'レ点リスト挿入',
				command: 'recommend3',
				icon:    __BASE__+'/assets/libs/fontawesome-free-5.9.0-web/svgs/solid/list-ul.svg'
			});
			this.addCommand('recommend3', {
				exec: function(editor) {
					editor.insertHtml(
						'<ul class="ListCheck"><li>項目1</li><li>項目2</li><li>項目3</li></ul>'
					);
				}
			});
		});

		$target.data('cke', cke)
	}

}

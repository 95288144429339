
$(function()
{
	/**
	 * ボタンリンク
	 */
	$('[data-href]').on('click', function(){
		location.href = $('base').attr('href') + $(this).data('href')
	})

	/**
	 * ボタンリンク（フォーム飛ばし用）
	 */
	$('[data-action]').on('click', function(){
		$(this).parents('form').attr('action', $(this).data('action')).submit()
	})

	/**
	 * 削除ボタン
	 */
	$('[data-delete]').on('click', function () {
		var $me = $(this)
		var alert = $(this).data('alert').replace(/\$([a-zA-Z_-]+)/g, function (all, match) {
			return $me.parents('tr').find('td.' + match).text().replace(/^\s+|\s+$/g, '')
		}).replace(/\\n/g, '\n')
		if (confirm(alert)) {
			$('form#delete').attr('action', $('base').attr('href') + $(this).data('delete'))
			$('form#delete').submit()
		}
	})

	/**
	 * 一覧テーブルの順序入れ替えD&D
	 */
	if ($('table.for-sort').length) {
		$('table.for-sort tbody')
			.sortable({
				axis: 'y',
				handle: 'td.move',
				opacity: 0.9,
				scrollSpeed: 5,
				containment: 'tbody',
				tolerance: 'pointer',
				helper: function(event, tr){
					tr.children().each(function(){
						$(this).width($(this).width());
					});
					return tr;
				},
			})
			.on('sortstop', function ()
			{
				// 背景色再割当て
				var num = 0;
				$(this).find('tr').each(function(){
					num ++
					if($(this).hasClass('disabled')) return;
					if(num % 2) {
						$(this).removeClass('pure-table-even')
						$(this).addClass('pure-table-odd')
					} else {
						$(this).addClass('pure-table-even')
						$(this).removeClass('pure-table-odd')
					}
				})

				// DB書き換えajax
				$.ajax({
					type: 'PUT',
					url: $('table.for-sort').data('ordering-url'),
					data: {
						ids: $(this).sortable('toArray', {attribute: 'data-id'}),
						csrf_name: $('input[name=csrf_name]').val(),
						csrf_value: $('input[name=csrf_value]').val(),
					},
					success: function(json){
						if('ok'===json.result) {
							console.info('Saved ordering.')
						} else {
							alert(json.message)
						}
					},
					dataType: 'json'
				});
			})
	}

	/**
	 * 現在の日付を published_at* 要素 にセット
	 */
	$('#set-publish').on('click', function(){
		var date   = new Date();
		var year   = date.getFullYear()
		var month  = date.getMonth()+1
		var day    = date.getDate()
		var hour   = date.getHours()
		var minute = date.getMinutes()
		if(month<10)  month  = '0'+month
		if(day<10)    day    = '0'+day
		if(hour<10)   hour   = '0'+hour
		if(minute<10) minute = '0'+minute
		$('[name=published_at_date]').val(year+'-'+month+'-'+day)
		$('[name=published_at_time]').val(hour+':'+minute)

		// Validon更新
		validon.send(['published_at_date'])
	})
});
